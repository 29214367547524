<template>
  <div :class="`status status--${translate}`">
    <div v-if="!noIcon">
      <v-img width="17px" :src="require(`@/assets/${translate}-square.svg`)" />
    </div>
    <div v-if="!noText">
      {{ $t(payment.status).toLowerCase() }}
    </div>
  </div>
</template>

<script>
import payments from "../../mixins/payments";
export default {
  props: {
    payment: {
      type: Object,
      default: () => ({
        status: "pending",
        message: "",
      }),
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    noText: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [payments],
  components: {},
  computed: {
    translate: function () {
      return this.translateStatus(this.payment);
    },
  },
  data: () => ({}),
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.status {
  color: #eacb00;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 7px;

  &.status--success {
    border-color: #0ac229;
    color: #0ac229;
  }

  &.status--danger {
    border-color: #e63946;
    color: #e63946;
  }

  .v-image {
    margin-top: 3px;
  }
}
</style>
