<template>
  <div v-if="user" class="payment-buttons svg-buttons" style="white-space: nowrap">
    <v-btn
      small
      icon
      class="ml-2 pl-0 pr-0 w-40"
      @click="$router.push({ name: 'payments-details', params: { id: payment.id } })"
    >
      <v-img max-width="24px" :src="require(`@/assets/details.svg`)" />
    </v-btn>
    <v-btn
      v-if="payment.status == 'pending' && isClinicDependant(user.level)"
      small
      icon
      color="success"
      class="ml-2 pl-0 pr-0 w-40"
      @click="$router.push({ name: 'payments-edit', params: { id: payment.id } })"
    >
      <v-img max-width="24px" :src="require(`@/assets/edit.svg`)" />
    </v-btn>
    <v-btn small icon color="error" class="ml-2 pl-0 pr-0 w-40">
      <v-img max-width="24px" :src="require(`@/assets/delete.svg`)" />
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import usersMixin from "../../mixins/users";

export default {
  props: {
    payment: {
      type: Object,
      default: () => ({
        status: "pending",
      }),
    },
  },
  mixins: [usersMixin],
  components: {},
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  data: () => ({
    user: null,
  }),
  async created() {
    this.user = await this.getUserInfo;
    console.log(this.user);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.payment-buttons {
  .v-btn {
    height: 40px !important;
    &.w-40 {
      width: 40px;
    }
  }
}
</style>
