<template>
  <div :class="`amount-translated amount-translated--${translate}`">
    <span v-if="isPending">
      {{
        `${isSingle() ? "" : `/ ${$options.filters.money(payment.amount)} `}${$t(
          "of-debt"
        )}`
      }}
    </span>
  </div>
</template>

<script>
import payments from "../../mixins/payments";

export default {
  props: {
    payment: {
      type: Object,
      default: () => ({
        status: "pending",
        message: "",
      }),
    },
  },
  mixins: [payments],
  components: {},
  computed: {
    translate: function () {
      return this.translateStatus(this.payment);
    },
  },
  data: () => ({}),
  async created() {},
  methods: {
    isSingle: function () {
      return "unique" == this.payment.type;
    },
    isPending: function () {
      return ["pending", "confirmed", "processing", "partially-paid"].includes(
        this.payment.status
      );
    },
    isPaid: function () {
      return ["paid"].includes(this.payment.status);
    },
    isFailed: function () {
      return ["cancelled", "failed"].includes(this.payment.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.amount-translated {
  color: #999999;
  font-weight: 400;
  font-size: 16px;

  &.amount-translated--danger {
    border-color: #e63946;
  }
}
</style>
