<template>
  <v-row class="payments-container">
    <v-col v-if="!includePrevious && !noPrevious" cols="12" class="px-0 pt-0 pb-0">
      <v-btn small text class="fg-blue v-btn--text" @click="includePrevious = true">
        <v-img max-width="22px" :src="require(`@/assets/time-circle.svg`)" />
        {{ $t("view-previous-payments") }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-row class="payments-table">
        <v-col v-if="totalItems > 0" cols="12" class="mt-0 px-5 py-0">
          <v-row v-for="(items, date) of payments" :key="date">
            <v-col cols="12" sm="9" class="px-0 pt-0 pb-0">
              <h3>
                {{
                  `${
                    today() == fromDate(date).format("YYYY-MM-DD")
                      ? `${$t("today")},`
                      : ""
                  } ${fromDate(date).format("MMM D")}`
                }}
              </h3>
            </v-col>
            <v-col cols="12" sm="3" class="fg-gray d-flex fz-small justify-content-end">
              {{ `${$t("total-payments")}: ${items.total}` }}
            </v-col>
            <v-col
              v-for="item of items.items"
              :key="item.id"
              cols="12"
              class="payments-table-row"
            >
              <attribute>
                <template #name>
                  {{ `${item.payment.patient.name} ${item.payment.patient.last_name}` }}
                </template>
                PRN: {{ item.payment.patient.prn }}
              </attribute>
              <attribute>
                <template #name>
                  {{
                    $t(
                      item.payment.type === "unique"
                        ? "unique-payment"
                        : "multiple-payment"
                    )
                  }}
                </template>
                <status :payment="item" />
              </attribute>
              <attribute>
                <template #name> {{ item.payment.amount | money }} USD </template>
                <amount-translated :payment="item.payment" />
              </attribute>
              <div>
                <buttons :payment="item.payment" />
              </div>
            </v-col>
          </v-row>
          <v-row justify-content="end">
            <v-col class="mt-2 d-flex justify-content-end">
              <v-pagination
                v-model="page"
                :total-visible="5"
                :length="parseInt((totalItems - 1) / 20 + 1)"
                :disabled="totalItems <= 20"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="totalItems == 0" cols="12" class="mt-0 px-5">
          <slot name="empty-search">
            {{ $t("no-entries-were-found-matching-criteria") }}
          </slot>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Status from "../components/PaymentStatus";
import Buttons from "../components/PaymentButtons";
import Attribute from "../components/Attribute";
import AmountTranslated from "../components/AmountTranslated";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import payments from "../../requests/payments";
import paymentsMixin from "../../mixins/payments";

export default {
  props: {
    filters: {
      type: Object,
      default: null,
    },
    noPrevious: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [paymentsMixin],
  components: {
    Attribute,
    Status,
    Buttons,
    AmountTranslated,
  },
  data: () => ({
    includePrevious: false,
    page: 1,
    payments: [],
    loading: false,
    totalItems: 0,
    timeout: null,
  }),
  computed: {},
  watch: {
    includePrevious: function (value) {
      if (value) {
        this.reload();
      }
    },
    page: function () {
      this.reload();
    },
    filters: {
      handler: async function () {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(
          function () {
            if (this.page != 1) {
              this.page = 1;
            } else {
              this.reload();
            }
          }.bind(this),
          1000
        );
      },
      deep: true,
    },
  },
  created: async function () {
    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    this.reload();
  },
  methods: {
    today: function () {
      return dayjs().format("YYYY-MM-DD");
    },
    fromDate: function (date) {
      return dayjs(date);
    },
    statusToList: function (item) {
      return payments.statusToList(item);
    },
    reload: async function () {
      const params = {};
      if (this.filters) {
        if (this.filters.from) {
          params.from = dayjs(this.filters.from).format("YYYY-MM-DD");
        } else if (!this.includePrevious) {
          params.from = dayjs().format("YYYY-MM-DD");
        }
        if (this.filters.to) {
          params.to = dayjs(this.filters.to).format("YYYY-MM-DD");
        }
        if (this.filters.status && this.filters.status.length > 0) {
          params.status = this.filters.status[0] != -1 ? [...this.filters.status] : [];
          if (params.status.includes("pending") && !params.status.includes("confirmed")) {
            params.status.push("confirmed");
          }
        }
        if (this.filters.type) {
          if (this.filters.type.length > 0 && this.filters.type[0] != -1) {
            params.type = this.filters.type[0];
          }
        }
      }
      params.length = 20;
      params.start = (this.page - 1) * params.length;

      const response = await payments.schedules(params);
      this.payments = response.items;
      this.totalItems = response.total;
      this.$emit("syncTotals", this.totalItems);
    },
  },
};
</script>

<style lang="scss" scoped>
.payments-container {
  align-items: flex-start;
  align-content: flex-start;
  > div:first-child {
    height: 40px;
  }
  .payments-table {
    h3 {
      font-size: 22px;
      font-weight: 700;
      color: #000000;
      margin-top: 30px;
    }

    .payments-table-row {
      display: flex;
      justify-content: space-between;
      box-shadow: inset 0px -1px 0px #e6e6e6;
      padding: 10px 0;
      > div {
        width: 30%;
        &:first-child {
          width: 30%;
        }
        &:nth-child(2) {
          width: 30%;
        }
      }
    }
  }
}
</style>
